import '../../../../../src/components/misc/neb-icon';
import '../neb-radio-button';
import '../neb-text';

import { html, css } from 'lit';

import { getProviderAdjustmentsTotal } from '../../../../../src/utils/payment-util';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { FEATURE_FLAGS, getFeatures } from '../../../../neb-utils/feature-util';
import {
  centsToCurrency,
  formatDollarAmountWithNegative,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import { formatPaymentNumber } from '../../../../neb-utils/neb-ledger-util';
import { isPayerPayment } from '../../../../neb-utils/neb-payment-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import Table, { ELEMENTS as ELEMENTS_BASE } from './neb-table';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  radio: { selector: 'neb-radio-button' },
  patientCells: { selector: '[id^=patient-]' },
  patientValues: { selector: '[id^=patient-values-]' },
  payerLinks: { selector: '[id^=payer-]' },
  payerValues: { selector: '[id^=payer-values-]' },
  paymentNumbers: { selector: '[id^=payment-number-]' },
  providerAdjustment: { selector: '[id^=provider-adjustment-]' },
  iconEdit: { selector: '[id^=icon-edit-]' },
};

class NebTableAllocationPayments extends Table {
  static get properties() {
    return {
      __hasRcmProviderAdjustmentsFF: Boolean,
    };
  }

  initState() {
    super.initState();

    this.onClickRadio = () => {};

    this.onClickDetail = () => {};

    this.onPayerUpdate = () => {};

    this.onEditProviderAdjustment = async () => {};

    this.fromEraEobPage = false;

    this.__hasRcmProviderAdjustmentsFF = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      clickRadio: e => {
        const index = Number(e.currentTarget.getAttribute('row'));
        this.onClickRadio(index);
      },
      clickDetail: e => {
        const index = Number(e.currentTarget.getAttribute('row'));
        const payment = this.model[index];
        this.onClickDetail({
          paymentId: payment.id,
          patientId: payment.patientId,
          payerPlanId: payment.payerPlanId,
          paymentMethod: payment.paymentMethod,
        });
      },
      selectPayer: async e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { payerPlanId } = this.model[rowIndex];

        if (payerPlanId) {
          const res = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
            id: payerPlanId,
          });

          if (res) await this.onPayerUpdate();
        }
      },
      editProviderAdjustment: e => {
        e.stopPropagation();
        const rowIndex = Number(e.currentTarget.getAttribute('index'));
        return this.onEditProviderAdjustment(rowIndex);
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();
    const features = await getFeatures();

    this.__hasRcmProviderAdjustmentsFF = features.includes(
      FEATURE_FLAGS.RCM_PROVIDER_ADJUSTMENTS,
    );
  }

  getConfig() {
    return [
      {
        key: 'checked',
        label: '',
        flex: css`0 0 40px`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'transactionDate',
        label: 'Transaction Date',
        flex: css`0 0 180px`,
        formatter: d => (d ? d.format('MM/DD/YYYY') : ''),
      },
      {
        sortable: true,
        truncate: true,
        key: 'paymentNumber',
        label: 'ID',
        flex: css`0 0 80px`,
      },
      {
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'patientName',
        label: 'Patient',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'codePayment',
        label: 'Type',
        flex: css`0 0 80px`,
        formatter: c => c.code,
      },
      {
        truncate: true,
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
        maxLength: 11,
        mask: currency,
        formatter: a => centsToCurrency(a),
      },
      ...(!this.__hasRcmProviderAdjustmentsFF
        ? []
        : [
            {
              key: 'providerAdjustments',
              label: 'Remit Offset',
              flex: css`1 0 0`,
            },
          ]),
      ...(this.__isPayerPayment()
        ? [
            {
              truncate: true,
              key: 'referenceId',
              label: 'Reference ID',
              flex: css`1 0 0`,
              formatter: v => v || '-',
            },
          ]
        : []),
      {
        truncate: true,
        key: 'allocated',
        label: 'Allocated',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a || 0),
      },
      {
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a || 0),
      },
      ...(this.fromEraEobPage
        ? []
        : [
            {
              key: 'detail',
              label: ' ',
              flex: css`0 0 12px`,
            },
          ]),
    ];
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.config = this.getConfig();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .cell-detail {
          cursor: pointer;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon-edit {
          position: relative;
          transition: 250ms ease-in-out;
          width: 25px;
          height: 15px;
          z-index: 1;
        }

        .icon-edit:hover {
          cursor: pointer;
          opacity: 0.6;
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .link {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .text {
          margin-right: 6px;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
          width: inherit;
        }
      `,
    ];
  }

  __isPayerPayment() {
    return this.model && this.model.length && isPayerPayment(this.model[0]);
  }

  __renderIcon() {
    return html` <neb-icon class="icon" icon="neb:open"></neb-icon> `;
  }

  __renderPatientCell({ value, rowIndex }, truncate) {
    return value
      ? html`
          <div id="patient-${rowIndex}" class="cell" index="${rowIndex}">
            <span
              id="patient-values-${rowIndex}"
              class="text"
              ?truncate="${truncate}"
              >${value}</span
            >
          </div>
        `
      : '-';
  }

  __renderPayerCell({ value, rowIndex }, payerPlanId) {
    return html`
      <div
        id="payer-${rowIndex}"
        class="${payerPlanId ? 'button' : 'cell'}"
        index="${rowIndex}"
        @click="${this.handlers.selectPayer}"
      >
        <span
          id="payer-values-${rowIndex}"
          class="${payerPlanId ? 'link' : 'text'}"
          >${value}</span
        >
      </div>
    `;
  }

  __renderPaymentId({ value, rowIndex }) {
    const paymentNumber = formatPaymentNumber(this.model[rowIndex]);

    return value
      ? html` <div id="payment-number-${rowIndex}">${paymentNumber}</div> `
      : '-';
  }

  __renderPaymentType({ value, rowIndex, columnConfig }) {
    const { codeDiscount } = this.model[rowIndex];

    let paymentTypeValue = value;

    if (codeDiscount) {
      paymentTypeValue = columnConfig.formatter
        ? columnConfig.formatter(codeDiscount)
        : codeDiscount.code;
    }

    return html`
      <neb-text
        id="${columnConfig.key}-${rowIndex}"
        ?truncate="${columnConfig.truncate}"
        >${paymentTypeValue}</neb-text
      >
    `;
  }

  __renderProviderAdjustments({ value, rowIndex }) {
    return html`
      <neb-icon
        id="icon-edit-${rowIndex}"
        class="icon icon-edit"
        icon="neb:edit"
        @click="${this.handlers.editProviderAdjustment}"
      ></neb-icon>
      <neb-text id="provider-adjustment-${rowIndex}">
        ${formatDollarAmountWithNegative(
          getProviderAdjustmentsTotal(value),
        )}</neb-text
      >
    `;
  }

  // eslint-disable-next-line complexity
  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'checked':
        return html`
          <neb-radio-button
            id="radio-${rowIndex}"
            row="${rowIndex}"
            tabindex="-1"
            ?checked="${value}"
            @click="${this.handlers.clickRadio}"
          ></neb-radio-button>
        `;

      case 'detail':
        return html`
          <neb-icon
            id="icon-detail-${rowIndex}"
            class="cell cell-detail"
            icon="neb:chevron"
            key="SPECIAL.detail"
            row="${rowIndex}"
            @click="${this.handlers.clickDetail}"
          ></neb-icon>
        `;

      case 'payerName': {
        const { payerPlanId } = this.model[rowIndex];

        return this.__renderPayerCell({ value, rowIndex }, payerPlanId);
      }

      case 'patientName':
        return this.__renderPatientCell(
          { value, rowIndex },
          columnConfig.truncate,
        );

      case 'paymentNumber':
        return this.__renderPaymentId({ value, rowIndex, columnConfig });

      case 'codePayment':
        return this.__renderPaymentType({ value, rowIndex, columnConfig });

      case 'providerAdjustments':
        return this.__renderProviderAdjustments({ value, rowIndex });

      default:
        return html`
          <neb-text
            id="${columnConfig.key}-${rowIndex}"
            ?truncate="${columnConfig.truncate}"
            >${value}</neb-text
          >
        `;
    }
  }
}

customElements.define(
  'neb-table-allocation-payments',
  NebTableAllocationPayments,
);
