import '../../../../packages/neb-www-practice-charting/src/components/diagnosis/neb-charting-diagnosis-detail';

import { openPopup } from '@neb/popup';
import { css, html } from 'lit';

import { updateEncounterDiagnoses } from '../../../../packages/neb-api-client/src/encounters-api-client';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { saveProblemList } from '../../../../packages/neb-redux/actions/problem-list-actions';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { ProviderService } from '../../../../packages/neb-redux/services/provider';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { dateToIcd10Year } from '../../../../packages/neb-utils/icd10-util';
import { mapProviderNameToProblems } from '../../../../packages/neb-utils/neb-charting-util';
import { openError, openSuccess } from '../../../store';
import { CSS_COLOR_YELLOW } from '../../../styles';
import { PANELS } from '../neb-page-unsigned-encounter';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';
import {
  didRouteChange,
  getNotesItemFromLocalStorage,
  isNotesDirty,
  removeNotesItemFromLocalStorage,
} from '../notes/neb-chart-notes-local-storage-util';

const PROBLEM_LIST_ERROR_MESSAGE = 'Unable to update problem list';
const PROBLEM_LIST_SUCCESS_MESSAGE = 'Problem added to problem list';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
  warningIcon: { id: 'warning-icon' },
};

export const DIAGNOSIS_ITEM_NAME = 'Diagnoses';

class NebUnsignedEncounterDiagnosesDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      problemList: Array,
      showWarnings: Boolean,

      __problems: Array,
      __providers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon-warning {
          width: 24px;
          height: 20px;
          padding-left: 5px;
          vertical-align: bottom;
          fill: ${CSS_COLOR_YELLOW};
        }
      `,
    ];
  }

  static get config() {
    return {
      itemName: DIAGNOSIS_ITEM_NAME,
      refetchKey: PANEL_KEYS.DIAGNOSIS,
    };
  }

  static createModel() {
    return [];
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  getTitle() {
    return this.model.length > 0
      ? `${DIAGNOSIS_ITEM_NAME} (${this.model.length})`
      : DIAGNOSIS_ITEM_NAME;
  }

  initState() {
    super.initState();

    this.problemList = [];
    this.showWarnings = false;

    this.__problems = [];
    this.__providers = [];

    this.onUpdateEncounter = () => {};
  }

  initServices() {
    super.initServices();

    this.__providerService = new ProviderService(({ providers }) => {
      this.__providers = providers.map(x => ({
        id: x.id,
        name: x.name,
      }));
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addToProblems: async icd10Info => {
        const model = {
          title: 'Add to Problem List',
          problem: { ...icd10Info, onsetDate: parseDate() },
        };
        const { info } = await openPopup(POPUP_RENDER_KEYS.PROBLEMS, model);

        if (info) {
          const { patientId } = this.encounter;
          const problems = [info, ...this.problemList];
          const response = await store.dispatch(
            saveProblemList(patientId, problems),
          );

          if (response.type === 'ITEM_UPDATE_ERROR') {
            openError(PROBLEM_LIST_ERROR_MESSAGE);
          } else {
            this.onRefetch(PANEL_KEYS.PROBLEM_LIST);

            openSuccess(PROBLEM_LIST_SUCCESS_MESSAGE);
          }
        }
      },
      codeBundleAdded: () => this.onRefetch(PANEL_KEYS.DIAGNOSIS),
      updateEncounter: () => this.onUpdateEncounter(),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__providerService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__providerService.disconnect();
  }

  isDirty() {
    const notesItem = getNotesItemFromLocalStorage();

    return (
      this.__elements.view.isDirty() || (notesItem && isNotesDirty(notesItem))
    );
  }

  close() {
    const notesItem = getNotesItemFromLocalStorage();

    if (notesItem) {
      this.__preventDirtyChecking = true;
      return this.onClose({ navigateTo: PANELS.CHART_NOTES });
    }

    return this.onClose();
  }

  proceededFromDirty() {
    if (didRouteChange()) {
      removeNotesItemFromLocalStorage();
    }

    super.proceededFromDirty();
  }

  save(savedCodes) {
    return updateEncounterDiagnoses(this.encounter.id, savedCodes, true);
  }

  updated(changedProps) {
    if (changedProps.has('problemList') || changedProps.has('__providers')) {
      this.__problems = mapProviderNameToProblems({
        problems: this.problemList,
        providers: this.__providers,
      });
    }
  }

  renderWarnings() {
    return this.showWarnings
      ? html`
          <neb-icon
            id="${ELEMENTS.warningIcon.id}"
            class="icon-warning"
            icon="neb:warning"
          ></neb-icon>
        `
      : '';
  }

  renderContent() {
    const providerId = this.encounter.providerId
      ? this.encounter.providerId
      : '';

    const icd10Year = dateToIcd10Year(this.encounter.serviceDate);

    return html`
      <neb-charting-diagnosis-detail
        id="${ELEMENTS.view.id}"
        .providerId="${providerId}"
        .encounterId="${this.encounter.id}"
        .encounter="${this.encounter}"
        .originalCodes="${this.model}"
        .savedProblems="${this.__problems}"
        .visible="${true}"
        .onSave="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onAddToProblems="${this.handlers.addToProblems}"
        .onCodeBundleAdded="${this.handlers.codeBundleAdded}"
        .onUpdateEncounter="${this.handlers.updateEncounter}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .layout="${this.layout}"
        .icd10Year="${icd10Year}"
      ></neb-charting-diagnosis-detail>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-diagnoses-detail',
  NebUnsignedEncounterDiagnosesDetail,
);
