import {
  updateCase,
  updateEncounterCurrentIllnessDateWithCaseDOO,
} from '../../../packages/neb-api-client/src/encounters-api-client';
import { fetchMany as getPatientCases } from '../../../packages/neb-api-client/src/patient-cases';
import { getPatientGuarantors } from '../../../packages/neb-api-client/src/patient-guarantor-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { updated } from '../../utils/update-notifications';
import {
  UPDATE_ENCOUNTER_CASE_BANNER_SUCCESS,
  UPDATE_ENCOUNTER_CASE_BANNER_ERROR,
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS,
  UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR,
} from '../../utils/user-message';

export const FORCE_RELOAD_ENCOUNTER = {
  forceReloadEncounter: Symbol('forceReloadEncounter'),
};

export const forceReloadEncounter = () => updated(FORCE_RELOAD_ENCOUNTER);

export async function getPatientActiveCases(patientId) {
  const cases = await getPatientCases(patientId);
  if (cases.length > 0) return cases.filter(c => c.active);

  return [];
}

export async function createNewCase(patientId) {
  let isFirstCase = true;

  const activeCases = await getPatientActiveCases(patientId);
  if (activeCases && activeCases.length > 0) isFirstCase = false;

  const guarantors = await getPatientGuarantors(patientId, {}, true);

  const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
    context: {
      patientId,
      guarantors: guarantors.filter(g => g.relation !== 'Self'),
      isFirstCase,
    },
  });

  return newCase;
}

export async function updateAssociatedCase({
  encounterIds,
  appointmentIds,
  patientId,
  newCaseId,
  newPatientAuthorizationId,
}) {
  try {
    await updateCase({
      encounterIds,
      appointmentIds,
      patientId,
      caseId: newCaseId || null,
      patientAuthorizationId: newPatientAuthorizationId || null,
    });

    store.dispatch(openSuccess(UPDATE_ENCOUNTER_CASE_BANNER_SUCCESS));
    return true;
  } catch (e) {
    store.dispatch(openError(UPDATE_ENCOUNTER_CASE_BANNER_ERROR));
    return false;
  }
}

export async function updateEncounterDOOCaseDOO(encounterId, body) {
  try {
    await updateEncounterCurrentIllnessDateWithCaseDOO(encounterId, body);

    store.dispatch(openSuccess(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS));
  } catch (e) {
    store.dispatch(openError(UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR));
  }
}
