import '../../../../neb-lit-components/src/components/neb-date-picker';
import '../../../../neb-lit-components/src/components/neb-checkbox-old';

import { openPopup } from '@neb/popup';
import { LitElement, html, css } from 'lit';

import { updateEncounterDateOfCurrentIllness } from '../../../../neb-api-client/src/encounters-api-client';
import { update as updatePatientCase } from '../../../../neb-api-client/src/patient-cases';
import {
  openError,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { parseDate } from '../../../../neb-utils/date-util';

export const ELEMENTS = {
  gradualCheckbox: { id: 'gradual' },
  datePicker: { id: 'date-picker' },
};

const CLEAR_ACTION = {
  title: 'Clear Date of Onset',
  message: 'Do you wish to clear the Date of Onset for ALL future encounters?',
};

const SAVE_ACTION = {
  title: 'Set Date of Onset',
  message: 'Do you wish to use this Date of Onset for ALL future encounters?',
};

const REPLICATE_TO_CASE_ACTION = {
  title: 'Set Case Date of Onset',
  message:
    'Do you wish to update the Date of Onset for the associated case with the Encounter Date of Onset?',
};

class NebChartingDiagnosisDateoOfCurrentIllness extends LitElement {
  static get properties() {
    return {
      __gradualBool: Boolean,
      __placeholderText: String,
      __selectedDate: Object,
      __isCaseAvailable: Boolean,
      __isPopupDisplayed: Boolean,
      __readOnlyDatePicker: Boolean,

      encounter: Object,
    };
  }

  static get styles() {
    return css`
      .container-header-right {
        display: flex;
        align-items: center;
      }

      .container-date {
        margin-right: 10px;
      }

      .container-date span {
        display: block;
        margin-bottom: 10px;
      }

      .checkbox-container {
        margin-top: 4px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__clearDefault = false;
    this.__gradualBool = false;
    this.__placeholderText = 'Select';
    this.__selectedDate = null;
    this.__isPopupDisplayed = false;
    this.__readOnlyDatePicker = true;

    this.encounter = null;

    this.onUpdateEncounter = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      handleGradualCheck: async gradual => {
        if (this.__isPopupDisplayed) return;

        this.__handleGradualCheck(gradual);

        const replicateToCase =
          gradual && this.__isCaseAvailable
            ? await this.__openConfirmationPopup(REPLICATE_TO_CASE_ACTION)
            : false;

        let future = false;

        if (!this.__isCaseAvailable) {
          if (gradual) {
            future = await this.__openConfirmationPopup(SAVE_ACTION);
          } else if (this.__clearDefault) {
            future = await this.__openConfirmationPopup(CLEAR_ACTION);
          }
        }

        this.__selectedDate = '';

        await this.__saveCurrentIllnessDate(
          {
            gradual,
          },
          {
            future,
            replicateToCase,
          },
        );
      },
      handleSelectDate: async date => {
        if (this.__isPopupDisplayed || date?.diff(this.__selectedDate) === 0) {
          return;
        }

        this.__handleSelectDate(date);

        const replicateToCase = this.__isCaseAvailable
          ? await this.__openConfirmationPopup(REPLICATE_TO_CASE_ACTION)
          : false;
        const future = !this.__isCaseAvailable
          ? await this.__openConfirmationPopup(SAVE_ACTION)
          : false;

        await this.__saveCurrentIllnessDate(
          {
            date,
            gradual: false,
          },
          {
            future,
            replicateToCase,
          },
        );
      },
      clearDate: async () => {
        if (this.__isPopupDisplayed) return;

        const future =
          this.__clearDefault && !this.__isCaseAvailable
            ? await this.__openConfirmationPopup(CLEAR_ACTION)
            : false;

        this.__selectedDate = null;
        await this.__saveCurrentIllnessDate(
          {
            gradual: false,
          },
          {
            future,
          },
        );
      },
    };
  }

  __isDateSelectable(dateObject) {
    const currentDateObject = new Date();
    return currentDateObject >= dateObject;
  }

  async __openConfirmationPopup(displayedAction) {
    this.__isPopupDisplayed = true;

    const result = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      ...displayedAction,
      confirmText: 'YES',
      cancelText: 'NO',
    });

    this.__isPopupDisplayed = false;

    return result;
  }

  async __saveCurrentIllnessDate(selectedCurrentIllnessOption, opts) {
    const { date, gradual } = selectedCurrentIllnessOption;
    const { future, replicateToCase } = opts;

    try {
      if (this.__isCaseAvailable && !!replicateToCase) {
        await this.__updateDOOForCase({ date, gradual });
      }

      await updateEncounterDateOfCurrentIllness(this.encounter.id, {
        future: future || false,
        date: date || undefined,
        gradual,
      });

      store.dispatch(openSuccess('Date of Onset updated'));
    } catch (err) {
      store.dispatch(openError('Unable to update Date of Onset'));
    }
  }

  __setIllnessSelectedDate({ date, gradual }) {
    if (date) {
      this.__selectedDate = parseDate(date);
      this.__gradualBool = false;
    } else {
      this.__selectedDate = null;
      this.__gradualBool = gradual;
    }

    this.__placeholderText = gradual ? 'Gradual' : 'Select';
  }

  async __updateDOOForCase({ date = '', gradual }) {
    try {
      await updatePatientCase(
        this.encounter.patientId,
        {
          ...this.encounter.case,
          onsetSymptomsDate: gradual ? null : date || undefined,
        },
        false,
      );

      store.dispatch(openSuccess('Date of Onset in case updated'));
    } catch (error) {
      store.dispatch(openError('Unable to update Date of Onset in case'));
    }
  }

  __handleSelectDate(date) {
    this.__selectedDate = date;
    this.__gradualBool = false;
    this.__placeholderText = 'Select';
  }

  __handleGradualCheck(gradualBool) {
    this.__placeholderText = gradualBool ? 'Gradual' : 'Select';
    this.__selectedDate = null;
    this.__gradualBool = gradualBool;
  }

  __setEncounterDependentData() {
    const {
      currentIllnessGradual,
      currentIllnessDate,
      clearCurrentIllnessDefault,
    } = this.encounter;

    this.__setIllnessSelectedDate({
      gradual: currentIllnessGradual,
      date: currentIllnessDate
        ? parseDate(currentIllnessDate.substring(0, 10))
        : currentIllnessDate,
    });

    this.__clearDefault = clearCurrentIllnessDefault;

    this.__isCaseAvailable = !!this.encounter.case?.id;
  }

  updated(changedProps) {
    if (changedProps.has('encounter') && this.encounter) {
      this.__setEncounterDependentData();
    }
  }

  render() {
    return html`
      <div class="container-header-right">
        <div class="container-date">
          <span>Date of Onset</span>

          <neb-date-picker
            id="${ELEMENTS.datePicker.id}"
            .placeholder="${this.__placeholderText}"
            .selectedDate="${this.__selectedDate}"
            .isDateSelectable="${this.__isDateSelectable}"
            .onClear="${this.__handlers.clearDate}"
            .onClick="${this.__handlers.handleSelectDate}"
            .readOnly="${this.__readOnlyDatePicker}"
            momentFlag
          ></neb-date-picker>
        </div>

        <div class="checkbox-container">
          <neb-checkbox-old
            id="${ELEMENTS.gradualCheckbox.id}"
            label="Gradual"
            ?checked="${this.__gradualBool}"
            .onClick="${this.__handlers.handleGradualCheck}"
          ></neb-checkbox-old>
        </div>
      </div>
    `;
  }
}
customElements.define(
  'neb-charting-diagnosis-date-of-current-illness',
  NebChartingDiagnosisDateoOfCurrentIllness,
);
